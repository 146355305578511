import React from "react";
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-theme-material-ui'

import { makeStyles } from "@material-ui/core/styles";
// kit
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Primary from "components/Typography/Primary.js";

// @material-ui/icons
import Call from "@material-ui/icons/Call";
import Mail from "@material-ui/icons/Mail";
// image
import line_icon from "../../images/line_logo.svg";

import shingenStyle from "style/kit_shingenStyle.js";
const useStyles = makeStyles(shingenStyle);
const Styles = makeStyles((theme) => ({
  sec_title: {
    paddingRight: '0',
  },
  toiawase_area:{
    [theme.breakpoints.down('lg')]: {
      '& > div':{ marginBottom: '0', }
    },
  },
}))
export default function SectionText() {
  const classes = useStyles();
  const cl = Styles();
  return (
      <GridContainer justifyContent="center">
        <GridItem lg={12} xl={7} className={classes.textCenter + " " + cl.toiawase_area}>
          <Card className={classes.card_primary}>
            <CardBody>
              <h4>Lineで 気軽にマンツーマン相談</h4>
              <div className={classes.margin_p}>
                <p>
                  お部屋探しや駐車場探しのご依頼はもちろん、<br />
                  住宅や保険に関する疑問・質問・お困り事など、<br />
                  なんでもご相談ください！<br />
                </p>
              </div>
              <GridContainer justifyContent="center" className={classes.line_annai}>
                <GridItem lg={12} xl={9}>
                  <div className={classes.line_tomodachi_btn}>
                    <ul>
                      <li>
                        <StaticImage
                          src="../../images/line_oshin.png"
                          layout="fullWidth"
                          placeholder="blurred"
                          alt=""
                          className={classes.oshin}
                        /> 
                      </li>
                      <li>
                        <StaticImage
                          src="../../images/line_k_logo.svg"
                          layout="fullWidth"
                          placeholder="blurred"
                          alt="申源LINE公式アカウント"
                          className={classes.koshiki}
                        />
                        <div className={classes.fukidashi}>
                          <StaticImage
                            src="../../images/line_serifu1.svg"
                            layout="fullWidth"
                            placeholder="blurred"
                            alt=""
                          />
                          <Button
                            color="line"
                            href="https://page.line.me/ksn4504g?openQrModal=true"
                            className={classes.line_icon_btn}
                            >
                              <img src={line_icon} alt="" />
                              友だち追加
                          </Button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </GridItem>
                <GridItem lg={12} xl={3}>
                  <div className={classes.lineQR}>
                    <StaticImage
                      src="../../images/shingen_lineQR.jpg"
                      layout="fullWidth"
                      placeholder="blurred"
                      alt=""
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <p className={classes.tuika}>
                友だち追加ボタン or QRコードをスキャンで、友だち追加してください！
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem lg={12} xl={5}>
          <Card className={classes.card_primary}>
            <CardBody>
              <h4>お電話でのご相談</h4>
              <div className={classes.margin_p}>
                <Primary className={classes.tel}>
                  <Call />0532-56-2259
                </Primary> 
              </div>
            </CardBody>
          </Card>
          <Card className={classes.card_primary}>
            <CardBody>
              <h4>メールでのご相談</h4>
              <div className={classes.margin_p}>
                <Primary className={classes.mail}>
                  <Mail />toiawase@shingen.info
                </Primary>
              </div>
            </CardBody>
          </Card>
          <Card className={classes.card_primary}>
            <CardBody>
              <h4>ご来店でのご相談</h4>
              <div className={classes.margin_p + " bf_size"}>
                <b><Link to="/company#c2">ご来店のご案内</Link></b> をご確認ください
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
  );
}
